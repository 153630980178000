<template>
  <v-card elevation="0" outlined>
    <v-card-title>
      <v-row dense>
        <v-col cols="auto">
          {{title}}
        </v-col>
        <v-spacer />
        <v-col cols="auto">
          <date-picker :edit-mode="true" :model="selectedDtsFrom" :allowed-dates="d => d <= new Date().toISOString()" @select="v=>{ selectDateFrom(v) }" />
        </v-col>
        <v-col cols="auto">
          <date-picker :edit-mode="true" :model="selectedDtsTo" :allowed-dates="d => d <= new Date().toISOString()" @select="v=>{ selectDateTo(v) }" />
        </v-col>
        <v-col cols="20%">
          <v-select dense v-model="options.custCode" :items="customers" item-text="custName" @input="list" item-value="custCode" />
        </v-col>
        <v-col cols="20%">
          <v-select dense v-model="soKind" :items="soKindOptions" />
        </v-col>
        <v-col>
          <v-text-field dense v-model="soSearch"  @keyup.enter="list" />
        </v-col>
        <v-col cols="auto" class="justify-end"><v-btn small width="80" color="primary" @click="searchReset">전체</v-btn></v-col>
        <v-col cols="auto" class="justify-end"><v-btn small width="80" color="info" @click="btnProof()">사진없음</v-btn></v-col>
        <v-col cols="auto" class="justify-end"><v-btn small width="80" color="primary" @click="btnConfirm('N')">미확인</v-btn></v-col>
        <v-col cols="auto" class="justify-end"><v-btn small width="80" color="info" @click="btnConfirm('Y')">완료</v-btn></v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-data-table disable-filtering disable-sort single-select dense
                  :items="items" :headers="headers"
                  :loading="loading" item-key="no"
                  mobile-breakpoint="0" :footer-props="vs.footerPropsErsTable"
                  @click:row="selectItem"
      >
        <template v-slot:item.ersDts="{item}">
          {{vs.toDateYmd(item.ersDts)}}
        </template>
        <template v-slot:item.agency="{item}">
          {{ item.agencyName + '-' + item.agentName }}
        </template>
        <template v-slot:item.dispatch="{item}">
          {{item.dispatchKm}}Km
          <span v-html="vs.renderNight(item.isNight)" />|
          <span v-html="vs.renderDispatchKm(item.dispatchKm)" />|
          <span v-html="vs.renderAwayLevel(item.awayLevel)" />
        </template>
        <template v-slot:item.task="{item}">
          {{item.taskName}} <span v-if="item.taskDetail"> / {{item.taskDetail}}</span>
        </template>
        <template v-slot:item.isConfirmed="{item}">
          {{ item.isConfirmed ? '완료' : '미확인' }}
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import vs from '@/utils/viewSupport'
import api from '@/services/api'
import moment from 'moment'

export default {
  name: 'DispatchTable',
  props: {
    title: { type: String, default: '' },
    customers: { type: Array, default: () => { return [] } },
  },
  data() {
    return {
      selectedDtsFrom: moment().format('YYYY-MM-DD'),
      selectedDtsTo: moment().format('YYYY-MM-DD'),
      vs,
      moment,
      items: [],
      headers: [
        { text: '번호', value: 'ersId' },
        { text: '일시', value: 'ersDts' },
        { text: '차종', value: 'vehicleName' },
        { text: '위치', value: 'ersRequest.addrBase' },
        { text: '요청', value: 'ersRequest.reqMessage' },
        { text: '가맹점|기사', value: 'agency' },
        { text: '출동내용', value: 'dispatch' },
        { text: '조치내용', value: 'task' },
        { text: '할증', value: 'extAmt' },
        { text: '사진', value: 'proofCount' },
        { text: '전송', value: 'proofSendCount' },
        { text: '상태', value: 'isConfirmed' },
      ],
      soKind: null,
      soKindOptions: [
        { text: '가맹점 코드', value: 'code' },
        { text: '가맹점 명', value: 'name' },
        { text: '차량번호', value: 'plate' },
      ],
      soSearch: null,
      options: {},
      loading: false,
    }
  },
  methods: {
    list() {
      this.loading = true
      // console.log('----options--->', JSON.stringify(this.options))
      api.getTasksByQuery(this.options).then(r => {
        this.items = r.result
        // console.log('getTaskByQuery --->', r.result)
      }).finally(() => { this.loading = false })
    },
    selectItem(item) {
      this.$emit('select', item)
    },
    selectDateFrom(v) {
      console.log(v)
      this.selectedDtsFrom = v
      this.searchReset()
    },
    selectDateTo(v) {
      console.log(v)
      this.selectedDtsTo = v
      this.searchReset()
    },
    btnProof() {
      this.options.proof = 'N'
      this.options.confirm = null
      this.list()
    },
    btnConfirm(yn = null) {
      this.options.confirm = yn
      this.options.proof = null
      this.list()
    },
    searchReset() {
      this.soKind = 'name'
      this.soSearch = null
      this.options = {
        ersDtsFrom: this.selectedDtsFrom,
        ersDtsTo: this.selectedDtsTo,
        custCode: null,
        agencyName: null,
        agencyCode: null,
        proof: null,
        confirm: null,
        plateNo: null,
      }
      this.list()
    },
    setSoSearch() {
      switch (this.soKind) {
        case 'code' :
          this.options.agencyCode = this.soSearch
          break
        case 'name' :
          this.options.agencyName = this.soSearch
          break
        default :
          this.options.plateNo = this.soSearch
      }
    },
    fetchEntriesDebounced(callback) {
      // cancel pending call
      clearTimeout(this._timerId)
      // delay new call 500ms
      this._timerId = setTimeout(() => {
        this.options.page = 1
        callback()
      }, 500)
    }
  },
  mounted() {
    this.searchReset()
  },
  watch: {
    /* options: {
      deep: true,
      handler() {
       this.list()
      }
    }, */
    soKind() {
      if (this.soKind === 'code' && this.soSearch) {
        this.options.agencyCode = this.soSearch
      } else {
        this.options.agencyName = this.soSearch
      }
    },
    soSearch() {
      this.fetchEntriesDebounced(this.setSoSearch)
    }
  },
  components: {
    DatePicker: () => import('@/views/components/DatePicker')
  }
}
</script>

<style scoped>
.v-data-table >>> thead tr th {
  padding: 6px !important;
  font-weight: bold !important;
  background-color: black !important;
  color: white !important;
  font-size: 12px !important;
}

.v-data-table >>> tbody tr td {
  padding: 0px 0px 0px 0px !important;
  font-size: 11px !important;
}

>>> div.v-select__selections > div {
  font-size:14px !important;
}

</style>
